const placeholder = {
  placeholder: true,
  id: 0,
  name: 'unknown',
  rocket: {
    id: 0,
    name: 'unknown',
    configuration: 'unknown',
  },
  lsp: {
    id: 0,
    name: 'unknown',
    abbrev: 'unknown',
  },
  missions: [
    {
      id: 0,
      name: 'unknown',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      payloads: [
        {
          id: 0,
          name: 'unknown',
          mass: 'unknown',
        },
      ],
    },
  ],
  location: {
    id: 0,
    name: 'unknown',
    pads: [
      {
        id: 0,
        name: 'unknown',
      },
    ],
  },
}

export default placeholder
